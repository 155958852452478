<template>
  <div class="experimental">
    <!-- 面包屑 -->
    <div class="breadcrumb">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="experimental-main">
      <div class="experimental-main-top">
        <el-button type="primary" size="small" @click="createExperimental()">添加实验</el-button>
        <div class="top-right">
          <Input v-model="searchForm.keyword" :placeholder="$t('common_search_keyword')" clearable search
            :enter-button="true" @on-enter="changeSearch()" @on-search="changeSearch()" />
        </div>
      </div>
      <div class="search">
        <div class="search-item">
          <div class="name">
            实验类型:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="searchForm.type == item.id ? 'active' : ''" v-for="(item, index) in typeList"
                :key="index + 'type'" @click="changeSearch(item, 'type')">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <el-table :data="tableDataList" border size="small">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="auth_script" label="认证脚本">
          <template v-slot="{ row }">
            {{ authScriptList?.find(item => item.id === row.auth_script)?.name }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" width="140">
          <template v-slot="{ row }">
            {{ typeList?.find(item => item.id === row.type)?.name }}
          </template>
        </el-table-column>
        <el-table-column prop="timelong" label="时长"></el-table-column>
        <el-table-column prop="resource_count" label="关联资源数" width="100"></el-table-column>
        <el-table-column prop="data_count" label="关联试题数" width="100"></el-table-column>
        <el-table-column prop="desc" label="描述"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template v-slot="{ row }">
            <el-button type="primary" size="small" @click="association(row)">编辑</el-button>
            <el-button type="danger" size="small" @click="deleteExperiment(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagin class="pagin" :pagination="pagination" @currentChange="currentChange" @sizeChange="sizeChange" />
    </div>

    <!-- 关联资源抽屉 -->
    <el-drawer :visible.sync="associationShow" :size="1200" @close="drawerClose()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ associationTitle }}</div>
        <div class="drawer-title-btns"></div>
      </div>
      <div style="padding: 20px;">
        <div class="tabbar">
          <div :class="tabClass('0')" @click="changeTab('0')">基本信息</div>
          <div :class="tabClass('1')" @click="changeTab('1')">已绑定资源</div>
          <div :class="tabClass('2')" @click="changeTab('2')">全部资源</div>
          <div :class="tabClass('3')" @click="changeTab('3')">已绑定试题</div>
          <div :class="tabClass('4')" @click="changeTab('4')">全部试题</div>
        </div>
        <template v-if="tabActive === '0'">
          <el-form ref="formRef" :model="addEditForm" :rules="rules" size="small" label-width="120px">
            <el-form-item label="名称" prop="name">
              <el-input v-model="addEditForm.name" @focus="clearValidate('name')" placeholder="请输入实验名称"
                clearable></el-input>
            </el-form-item>
            <el-form-item label="认证脚本" prop="auth_script">
              <el-select v-model="addEditForm.auth_script" @focus="clearValidate('auth_script')" placeholder="请选择认证脚本"
                clearable style="width: 100%;">
                <el-option v-for="item in authScriptList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型" prop="type">
              <el-select v-model="addEditForm.type" @focus="clearValidate('type')" placeholder="请选择实验类型" clearable
                style="width: 100%;">
                <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"
                  :disabled="item.id === '-1'">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="描述">
              <el-input v-model="addEditForm.desc" type="textarea" placeholder="请输入实验描述"></el-input>
            </el-form-item>
            <el-form-item label="在线编程脚本">
              <el-select v-model="addEditForm.code_run_cmd" size="small" style="width: 100%;" placeholder="请选择在线编程脚本" clearable>
                <el-option v-for="item in scriptList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="在线编程docker">
              <el-select v-model="addEditForm.code_run_docker" size="small" style="width: 100%;" placeholder="请选择在线编程docker" clearable>
                <el-option v-for="item in dockerList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="docker实验镜像">
              <el-select v-model="addEditForm.docker_image" size="small" style="width: 100%;" placeholder="请选择docker环境镜像" clearable>
                <el-option v-for="item in dockerImageList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时长">
              <el-input v-model="addEditForm.timelong" placeholder="请输入时长"></el-input>
            </el-form-item>
            <el-form-item label="打开链接是否内嵌iframe">
              <el-input v-model="addEditForm.link_as_iframe" placeholder="0"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="drawerSubmit()">确定</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-if="tabActive === '1'">
          <el-table :data="associatedList" border>
            <el-table-column prop="source_id" label="序号" width="80"></el-table-column>
            <el-table-column label="名称">
              <template v-slot="{ row }">{{ row.source.name }}</template>
            </el-table-column>
            <el-table-column label="类型" width="100">
              <template v-slot="{ row }">
                {{ libTypeList?.find(item => item.id === row.source_type)?.name || '' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="110">
              <template v-slot="{ row }">
                <el-button type="warning" size="small" @click="unBindLib(row)"
                  :loading="unBindLoading && row.source_id === questionBankForm.source_id">解除关联</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-if="tabActive === '2'">
          <div class="search">
            <div class="search-item">
              <div class="name">
                资源类型:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="questionBankForm.type == item.id ? 'active' : ''" v-for="(item, index) in libTypeList"
                    :key="index" @click="libTypeChange(item)">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <el-table :data="libDataList" border>
            <el-table-column prop="id" label="序号" width="80"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="type" label="类型" width="100">
              <template v-slot="{ row }">
                {{ libTypeList?.find(item => item.id === row.type)?.name || '' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template v-slot="{ row }">
                <div v-if="row.isBind">已关联</div>
                <el-button v-else type="primary" size="small" @click="bindLib(row)"
                  :loading="bindLoading && row.id === questionBankForm.source_id">关联</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagin class="pagin" :pagination="paginationLib" @currentChange="currentChangeLib"
            @sizeChange="sizeChangeLib" />
        </template>
        <template v-if="tabActive === '3'">
          <Input v-model="testSearchForm.keyword" placeholder="模糊搜索" clearable search :enter-button="true"
            @on-search="getBoundTestList" style="width: 200px; margin-bottom: 20px;" />
          <el-table :data="bindTestList" size="small" border>
            <el-table-column label="ID" prop="data.id" width="100"></el-table-column>
            <el-table-column label="标题" prop="data.title"></el-table-column>
            <el-table-column label="难度" width="80">
              <template v-slot="{ row }">
                <div>{{ levelObj[row.data.level] }}</div>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template v-slot="{ row }">
                <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd') }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template v-slot="{ row }">
                <el-button type="primary" size="small" @click="unBindTest(row)">解除关联</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagin class="pagin" :pagination="testPagination" @currentChange="testCurrentChange"
            @sizeChange="testSizeChange" />
        </template>
        <template v-if="tabActive === '4'">
          <Input v-model="categorySearch.keyword" placeholder="模糊搜索" clearable search :enter-button="true"
            @on-search="getAllTestList()" style="width: 200px;" />
          <div class="search">
            <div class="search-item">
              <div class="name">
                类型:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="categorySearch.type == item.id ? 'active' : ''" v-for="item in categoryTypeList"
                    :key="item.id + 'type'" @click="categoryChange(item.id, 'type')">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <div class="search-item">
              <div class="name">
                一级分类:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="categorySearch.category_id == item.id ? 'active' : ''" v-for="item in categoryFirstList"
                    :key="item.id + 'firstCategory'" @click="categoryChange(item.id, 'category_id')">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <div class="search-item" v-if="categorySecondList.length > 1">
              <div class="name">
                二级分类:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="categorySearch.category_second_id == item.id ? 'active' : ''"
                    v-for="item in categorySecondList" :key="item.id + 'secondCategory'"
                    @click="categoryChange(item.id, 'category_second_id')">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <div class="search-item" v-if="categoryThirdList.length > 1">
              <div class="name">
                三级分类:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="categorySearch.category_third_id == item.id ? 'active' : ''"
                    v-for="item in categoryThirdList" :key="item.id + 'thirdCategory'"
                    @click="categoryChange(item.id, 'category_third_id')">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <el-table :data="allTestList" size="small" border>
            <el-table-column label="ID" prop="id" width="100"></el-table-column>
            <el-table-column label="标题" prop="title"></el-table-column>
            <el-table-column label="难度" width="80">
              <template v-slot="{ row }">
                <div>{{ levelObj[row.level] }}</div>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template v-slot="{ row }">
                <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd') }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template v-slot="{ row }">
                <div v-if="row.isBind">已关联</div>
                <el-button v-else type="primary" size="small" @click="bindTest(row)">关联</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagin class="pagin" :pagination="allTestPagination" @currentChange="allTestCurrentChange"
            @sizeChange="allTestSizeChange" />
        </template>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import Pagin from "@/components/myPagin.vue";
import util from "@/utils/tools.js";
import { MessageBox } from "element-ui";
export default {
  name: "ExperimentalManage",
  components: {
    Pagin
  },
  created() {
    this.getTableList();
  },
  data() {
    return {
      util,
      // 列表数据
      tableDataList: [],
      // 搜索数据
      searchForm: {
        keyword: "",
        type: "-1"
      },
      // 实验类型数据
      typeList: [],
      // 实验在线脚本数据
      scriptList: [],
      // 实验在线docker数据
      dockerList: [],
      dockerImageList:[],
      // 分页器数据
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },

      // 创建|编辑实验表单数据
      addEditForm: {
        id: "",
        name: "",
        type: "",
        desc: "",
        auth_script: "",
        parent_id: "",
        code_run_cmd: "",
        code_run_docker: "",
        docker_image:"",
        timelong:7200,
        link_as_iframe:0
      },
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: "请输入实验名称", trigger: "blur" }
        ],
        auth_script: [
          { required: true, message: "请选择认证脚本", trigger: "change" }
        ],
        type: [
          { required: true, message: "请选择实验类型", trigger: "change" }
        ]
      },
      // 关联资源抽屉可见性
      associationShow: false,
      // 关联资源抽屉标题
      associationTitle: "",
      // 当前激活的tab
      tabActive: "0",
      // 题库资源类型数据
      libTypeList: [],
      // 认证脚本数据
      authScriptList: [],
      // 题库资源列表
      libDataList: [],
      // 已关联的题库资源列表
      associatedList: [],
      // 题库资源搜索数据
      questionBankForm: {
        id: "", //此id是实验id
        type: "0"
      },
      // 分页器数据
      paginationLib: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      // 关联按钮加载状态
      bindLoading: false,
      // 解除按钮加载状态
      unBindLoading: false,
      // 已绑定试题分页器数据
      testPagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      testSearchForm: {
        keyword: ""
      },
      bindTestList: [],
      // 已绑定试题的id列表
      bindTestIdList: [],

      // 全部试题相关数据
      allTestPagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      allTestList: [],
      // 试题难度数据
      levelObj: {},
      // 全部试题分类筛选
      categorySearch: {
        type: "0",
        category_id: "-1",
        category_second_id: "-1",
        category_third_id: "-1",
        is_hide_children: "1",
        keyword: ""
      },
      // 分类类型数据
      categoryTypeList: [],
      // 一级分类数据
      categoryFirstList: [],
      categorySecondList: [],
      categoryThirdList: [],
    }
  },
  methods: {
    // 获取列表数据
    getTableList() {
      const { pagination, searchForm } = this;
      this.api.dataset.getExperimentList({
        page: pagination.page,
        page_size: pagination.pageSize,
        site_id: "-1",
        user_id: "-1",
        ...searchForm
      }).then(res => {
        const arr = [];
        Object.keys(res.types).forEach(key => {
          arr.push({
            id: key,
            name: res.types[key]
          });
        });
        arr.unshift({
          id: "-1",
          name: "全部"
        });
        this.typeList = arr;

        const arr2 = [];
        Object.keys(res.code_run_cmds).forEach(key => {
          arr2.push({
            id: key,
            name: res.code_run_cmds[key]
          });
        });
        this.scriptList = arr2;

        const arr3 = [];
        Object.keys(res.code_run_dockers).forEach(key => {
          arr3.push({
            id: key,
            name: res.code_run_dockers[key]
          });
        });
        this.dockerList = arr3;

        const arr4 = [];
        Object.keys(res.docker_images).forEach(key => {
          arr4.push({
            id: key,
            name: res.docker_images[key]
          });
        });
        this.dockerImageList = arr4;

        const arr1 = [];
        Object.keys(res.auth_scripts).forEach(key => {
          arr1.push({
            id: key,
            name: res.auth_scripts[key]
          });
        });
        this.authScriptList = arr1;
        this.tableDataList = res.list || [];
        pagination.total = Number(res.count);
      });
    },
    // 搜索项更改
    changeSearch(item, name) {
      const { searchForm } = this;
      if (name === "type") {
        searchForm[name] = item.id;
      }
      this.getTableList();
    },
    // 当前页改变
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getTableList();
    },
    // 每页条数改变
    sizeChange(size) {
      const { pagination } = this;
      pagination.pageSize = size;
      this.getTableList();
    },


    // 添加实验
    createExperimental() {
      this.associationTitle = "添加实验";
      this.tabActive = "0";
      this.associationShow = true;
    },
    // 删除实验
    deleteExperiment(row) {
      MessageBox.confirm("确定删除当前资源吗？", "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.dataset.deleteExperiment({
          id: row.id
        }).then(() => {
          this.getTableList();
          this.$Message.success("删除成功");
        });
      });
    },
    // 获取实验详情
    getDetail() {
      const { questionBankForm } = this;
      this.api.dataset.getExperSource({
        experiment_id: questionBankForm.id
      }).then(res => {
        this.associatedList = res.list || [];
      });
    },
    // 提交
    drawerSubmit() {
      const { addEditForm } = this;
      this.$refs.formRef.validate(valid => {
        if (valid) {
          if (!addEditForm.id) {
            this.api.dataset.createExperiment(addEditForm).then(res => {
              this.getTableList();
              // 创建完成可以编辑题库资源
              this.association(res.info);
              addEditForm.id = res.info.id;
              this.$Message.success("添加成功");
            });
          } else {
            this.api.dataset.editExperiment(addEditForm).then(() => {
              this.getTableList();
              this.$Message.success("编辑成功");
            });
          }
        }
      });
    },
    // 抽屉关闭
    drawerClose() {
      this.addEditForm = {
        id: "",
        name: "",
        type: "",
        desc: "",
        parent_id: "",
        auth_script: "",
        code_run_cmd: "",
        code_run_docker: "",
        docker_image:"",
        timelong:0,
        link_as_iframe:0
      }
      this.testPagination = {
        page: 1,
        pageSize: 10,
        total: 0
      }
      this.allTestPagination = {
        page: 1,
        pageSize: 10,
        total: 0
      }
      this.associationShow = false;
      this.$refs?.formRef?.resetFields();
    },
    // 清除某个字段的验证
    clearValidate(key) {
      this.$refs.formRef.clearValidate(key);
    },

    // 关联资源
    association(row) {
      const { questionBankForm, addEditForm } = this;
      this.associationTitle = `编辑实验-${row.name}`;
      questionBankForm.id = row.id;
      this.getDetail();
      this.getLibList();
      this.getBoundTestList();
      this.getCategoryForm();
      this.getAllTestList();
      Object.keys(addEditForm).forEach(key => {
        addEditForm[key] = row[key];
      });
      this.associationShow = true;
    },
    // 切换tab
    changeTab(id) {
      if (!this.addEditForm.id) {
        return;
      }
      this.tabActive = id;
    },
    // 样式
    tabClass(id) {
      let str = "";
      if (this.tabActive === id) {
        str += "tabActive";
      }
      if (!this.addEditForm.id && id !== "0") {
        str += "disable";
      }
      return str;
    },
    // 获取资源列表
    getLibList() {
      const { questionBankForm, paginationLib } = this;
      this.api.dataset.datasetLibList({
        type: questionBankForm.type,
        site_id: "-1",
        user: "-1",
        ...paginationLib
      }).then(res => {
        const arr = [];
        Object.keys(res.types).forEach(key => {
          arr.push({
            id: key,
            name: res.types[key]
          });
        });
        arr.unshift({
          id: "0",
          name: "全部"
        });
        this.libTypeList = arr;
        paginationLib.total = Number(res.count);
        this.libDataList = res.list || [];
      });
    },
    // 题库类型改变
    libTypeChange(item) {
      const { questionBankForm } = this;
      questionBankForm.type = item.id;
      this.getLibList();
    },
    // 绑定资源
    bindLib(row) {
      const { questionBankForm } = this;
      questionBankForm.source_id = row.id;
      this.bindLoading = true;
      this.api.dataset.bindLib({
        experiment_id: questionBankForm.id,
        source_ids: row.id,
        source_type: row.type
      }).then(() => {
        this.getDetail();
        this.getLibList();
        this.bindLoading = false;
        this.$Message.success("关联成功");
      });
    },
    // 解除关联
    unBindLib(row) {
      const { questionBankForm } = this;
      questionBankForm.source_id = row.source_id;
      this.unBindLoading = true;
      this.api.dataset.unBindLib({
        ids: row.source_id,
        experiment_id: questionBankForm.id,
        type: row.source_type
      }).then(() => {
        this.getDetail();
        this.getLibList();
        this.unBindLoading = false;
        this.$Message.success("解除关联成功");
      });
    },
    // 当前页改变
    currentChangeLib(page) {
      const { paginationLib } = this;
      paginationLib.page = page;
      this.getLibList();
    },
    sizeChangeLib(size) {
      const { paginationLib } = this;
      paginationLib.pageSize = size;
      this.getLibList();
    },

    // 获取已绑定试题
    getBoundTestList() {
      const { testPagination, testSearchForm, questionBankForm } = this;
      this.api.dataset.boundTestList({
        page: testPagination.page,
        page_size: testPagination.pageSize,
        ...testSearchForm,
        experiment_id: questionBankForm.id
      }).then(res => {
        console.log("已绑定试题", res);
        testPagination.total = Number(res.count);
        this.bindTestIdList = util.objToArr(res.data_ids)
        this.bindTestList = res.list || [];
      });
    },
    testCurrentChange(page) {
      const { testPagination } = this;
      testPagination.page = page;
      this.getBoundTestList();
    },
    testSizeChange(size) {
      const { testPagination } = this;
      testPagination.pageSize = size;
      this.getBoundTestList();
    },

    // 获取试题分类数据
    getCategoryForm() {
      const { categorySearch } = this;
      this.api.dataset.categoryForm(categorySearch).then(res => {
        console.log("分类数据", res);
        // 分类类型数据
        const arr = [];
        Object.keys(res.categoryTypes).forEach(key => {
          arr.push({
            id: key,
            name: res.categoryTypes[key]
          });
        });
        this.categoryTypeList = arr;
        // 一级分类数据
        res.categoryFirstList.unshift({ id: "-1", name: "全部" });
        this.categoryFirstList = res.categoryFirstList || [];
        // 二级分类数据
        res.categorySecondList.unshift({ id: "-1", name: "全部" });
        this.categorySecondList = res.categorySecondList || []
        // 三级分类数据
        res.categoryThirdList.unshift({ id: "-1", name: "全部" });
        this.categoryThirdList = res.categoryThirdList || [];
      });
    },
    categoryChange(id, name) {
      const { categorySearch } = this;
      if (name === "type") {
        categorySearch.category_id = "-1";
        categorySearch.category_second_id = "-1";
        categorySearch.category_third_id = "-1";
      }
      if (name === "category_id") {
        categorySearch.category_second_id = "-1";
        categorySearch.category_third_id = "-1";
      }
      if (name === "category_second_id") {
        categorySearch.category_third_id = "-1";
      }
      categorySearch[name] = id;
      this.getCategoryForm();
      this.getAllTestList();
    },
    // 获取全部试题
    getAllTestList() {
      const { allTestPagination, categorySearch } = this;
      this.api.dataset.dataList({
        // 分页数据赋值
        ...allTestPagination,
        keyword: categorySearch.keyword,
        // 认证，课程，教材
        category_type: categorySearch.type,
        // 优先取三级分类，没有取二级，二级没有取一级
        category_id: categorySearch.category_third_id !== "-1" ? categorySearch.category_third_id : categorySearch.category_second_id !== "-1" ? categorySearch.category_second_id : categorySearch.category_id,
        status: "1",// 试题状态1，固定已上架
        type: "10", // 试题类型10，固定实验
      }).then(res => {
        console.log("全部试题", res);
        allTestPagination.total = Number(res.count);
        this.levelObj = res.levels || {};
        res.list.forEach(item => {
          item.isBind = false;
        });
        this.allTestList = res.list || [];
      });
    },
    allTestCurrentChange(page) {
      const { allTestPagination } = this;
      allTestPagination.page = page;
      this.getAllTestList();
    },
    allTestSizeChange(size) {
      const { allTestPagination } = this;
      allTestPagination.pageSize = size;
      this.getAllTestList();
    },
    // 关联试题
    bindTest(row) {
      const { questionBankForm } = this;
      this.api.dataset.experBindTest({
        experiment_id: questionBankForm.id,
        data_ids: row.id
      }).then(() => {
        this.$Message.success("绑定成功");
        this.getBoundTestList();
        this.getAllTestList();
      });
    },
    // 解除关联试题
    unBindTest(row) {
      const { questionBankForm } = this;
      this.api.dataset.experUnBindTest({
        ids: row.data.id,
        experiment_id: questionBankForm.id
      }).then(() => {
        this.$Message.success("解绑成功");
        this.getBoundTestList();
        this.getAllTestList();
      });
    }
  },
  watch: {
    libDataList(newVal) {
      newVal.forEach(item => {
        if (this.associatedList.find(e => e.source_id === item.id)) {
          item.isBind = true;
        }
      });
    },
    associatedList(newVal) {
      this.libDataList.forEach(item => {
        if (newVal.find(e => e.source_id === item.id)) {
          item.isBind = true;
        }
      });
    },
    bindTestIdList: {
      handler(newVal) {
        const { allTestList } = this;
        allTestList.forEach(item => {
          if (newVal.find(e => e === item.id)) {
            item.isBind = true;
          }
        });
      },
      immediate: true
    },
    allTestList: {
      handler(newVal) {
        const { bindTestIdList } = this;
        newVal.forEach(item => {
          if (bindTestIdList.find(e => e === item.id)) {
            item.isBind = true;
          }
        });
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.experimental {
  .breadcrumb {
    margin: 20px 0 0 20px;
  }

  .experimental-main {
    background-color: white;
    border-radius: 4px;
    margin: 20px;
    padding: 20px;

    .experimental-main-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .pagin {
    text-align: end;
    margin-top: 20px;
  }

  .search {
    margin-top: 20px;
    margin-bottom: 20px;

    .search-item {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name {
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }

      .cont {
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values {
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div {
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }

          >div.active {
            background-color: #2d8cf0;
            color: #FFFFFF;
            border-radius: 4px;
          }
        }

        .more {
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;

          .more-icon {
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }

  .tabbar {
    border-bottom: 1px solid #dcdee2;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    margin-bottom: 20px;

    >div {
      margin-right: 20px;
      cursor: pointer;
      position: relative;
    }

    .tabActive {
      color: #4a79ff;

      &::after {
        content: "";
        display: block;
        height: 4px;
        width: 100%;
        background-color: #4a79ff;
        position: absolute;
        left: 0;
        bottom: -20px;
      }
    }

    .disable {
      color: #bdc3ca;
    }
  }

  .pagin {
    text-align: end;
    margin-top: 20px;
  }
}
</style>